import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    authValue: ''
  })
  const fnPhoneAuth = async () => {
    try {
      const res = await proxy.$MyPageSvc.postMyUserInfoSendAuthValue()
      if (res.resultCode === '0000') {
        console.log('resresres', res)
      } else {
        alert(res.resultMsg)
      }
    } catch (err) {
      console.error(err)
    }
  }
  const fnNext = async () => {
    if (!isValid()) return
    const params = {}
    params.havePwYn = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
    params.authValue = state.authValue
    const res = await proxy.$MyPageSvc.postMyUserInfoDetail(params)
    if (!proxy.$Util.isEmpty(res.userInfo)) {
      await dispatch('user/updateDetailForm', { detailForm: res.userInfo })
      await router.replace({ path: '/my-info/view' })
    } else {
      alert(res.resultMsg)
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.authValue)) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    if (state.authValue.length !== 6) {
      alert('인증번호를 정확히 입력해주세요.')
      return false
    }
    return true
  }

  const maskingAuthValue = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 6) {
      e.target.value = e.target.value.substr(0, 6)
    }
    state.authValue = String(e.target.value)
  }

  return { ...toRefs(state), fnPhoneAuth, fnNext, maskingAuthValue }
}
