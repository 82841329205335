<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>회원 정보</span>
          </div>
          <div class="inform">
            <div>
              회원님의 개인 정보 보호를 위해, 회원님의 휴대폰으로 SMS 인증번호를
              발송합니다.
            </div>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <button class="btn" @click="fnPhoneAuth">
                      인증번호 발송
                    </button>
                    <div class="inline">
                      <label class="input">
                        <input
                          type="number"
                          placeholder="인증번호(6자리 숫자)"
                          inputmode="decimal"
                          pattern="\d*"
                          @input="maskingAuthValue"
                          :value="authValue"
                        />
                      </label>
                      <button class="btn" @click="fnNext">확인</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-info-phone-auth-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
